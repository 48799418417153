import { useQuery } from '@apollo/client'
import { studentEnrollmentsQuery } from '../../../appsync/graphql/student'
import { fetchSchoolsByDICode } from '../../../rest/search'
import { USER_PROMPT_MODAL_UPDATE } from '../scoresend/constants'
import { formatAppsyncErrors } from '../../../appsync/utils'

const useStudentEnrollmentsQuery = ({ educationPeriod, otherSkipConditions = false }) => {
  const {
    loading,
    error: rawError = null,
    data: rawData = {},
  } = useQuery(studentEnrollmentsQuery, {
    variables: { code: educationPeriod },
    skip: !educationPeriod || otherSkipConditions,
  })
  const [scoreSendData, setScoreSendData] = useState({})
  const enrollmentsData = rawData?.getStudentEnrollments ?? {}

  useEffect(() => {
    if (enrollmentsData?.scoreSendData) {
      setScoreSendData(enrollmentsData?.scoreSendData ?? {})
    }
  }, [enrollmentsData?.scoreSendData])

  useEffect(() => {
    const getDiInfo = async () => {
      if (scoreSendData?.diCode) {
        const diInfo = await fetchSchoolsByDICode(scoreSendData.diCode, 1)
        // There is a diCode, but the selected org is not active
        if (scoreSendData.diCode && !diInfo?.suggestions[0]?.activeInd) {
          setScoreSendData({
            ...scoreSendData,
            diCode: null, // clear the diCode as user needs to provide new code
            userPromptType: USER_PROMPT_MODAL_UPDATE,
            org: diInfo.suggestions[0],
          })
        }
      }
    }
    getDiInfo()
  }, [scoreSendData?.diCode])

  const data = {
    ...enrollmentsData,
    courseEnrollments: enrollmentsData?.courseEnrollments ?? [],
    scoreSendData: { ...scoreSendData },
  }
  const error = rawError ? formatAppsyncErrors(rawError) : null
  return { data, loading, error }
}

export default useStudentEnrollmentsQuery
