import { PrimaryButton } from '@cb/apricot-react'
import { isDateSameOrAfter } from '@myap/ui-library'
import SimpleErrorMsg from '../../_common/SimpleErrorMsg'
import useUserSettingsQuery from '../../../hooks/useUserSettingsQuery'
import useDistrictReadinessReportUrlQuery from '../hooks/useDistrictReadinessReportUrlQuery'

export default function DistrictReadinessReportButton({ setDashboardUrl }) {
  const {
    userDetails: { roles, selectedOrgId: orgId },
    systemDates,
  } = useUserSettingsQuery()
  // Find the role for the selectedOrgId
  const roleForOrg = roles.find(role => role.orgId === orgId)
  // Use the parentOrgId of the selected org to make district data requests
  const districtOrgId = roleForOrg.parentOrgId
  // Check if deadline has passed for when the link goes active
  const currentDate = new Date()
  const districtReadinessActiveDate = new Date(systemDates.deadlines.districtReadinessActiveDate)
  const showButton = isDateSameOrAfter(currentDate, districtReadinessActiveDate)

  //Get District Readiness Report
  const {
    getDistrictReadinessReportUrl,
    data: readinessData,
    loading: readinessLoading,
    error: readinessError,
  } = useDistrictReadinessReportUrlQuery({ orgId: districtOrgId })

  useEffect(() => {
    setDashboardUrl(readinessData.embedUrl)
  }, [readinessData?.embedUrl])

  const handleClick = () => {
    getDistrictReadinessReportUrl()
  }

  if (showButton) {
    return (
      <div className="cb-margin-top-24 cb-margin-bottom-24">
        <PrimaryButton loading={readinessLoading} onClick={handleClick}>
          Digital AP Exam Readiness Dashboard
        </PrimaryButton>
        {readinessError ? <SimpleErrorMsg error={readinessError} /> : null}
      </div>
    )
  }
  return null
}
