import { Icon } from '@cb/apricot-react'

export default function SimpleErrorMsg({ error }) {
  return (
    <div style={{ fontSize: '.75rem', color: '#ab2334' }} aria-live="polite" tabIndex="-1">
      <p>
        <Icon
          name="exclamation"
          color="red1"
          circular
          style={{ display: 'inline-block', fontSize: '.65rem', top: '3px' }}
        />{' '}
        Error: {error}
      </p>
    </div>
  )
}
