import { useApolloClient } from '@apollo/client'
import {
  USER_PREVIEW_STUDENT,
  USER_PREVIEW_TEACHER,
  CMS_DISTRICT,
  isDistrictAdmin,
  isDistrictFundingAdmin,
  isDistrictSuperAdmin,
} from '@myap/metadata'
import {
  profile,
  StatusPanel,
  isNewLoggedInSession,
  setLoggedInToken,
  Spinner,
} from '@myap/ui-library'
import LastUpdated from '../_common/lastupdated/LastUpdated'
import Banner from '../_common/banner/Banner'
import Courses from '../professional/courses/Courses'
import SchoolTable from '../_common/courseaccordion/SchoolTable'
import SchoolToggle from '../_common/schooltoggle/SchoolToggle'
import Notifications from '../_common/content/notifications/Notifications'
import ProfessionalTimeline from '../professional/timeline/Timeline'
import TimelineTeaser from '../_common/content/timeline/TimelineTeaser'
import { EventsByDay } from '../_common/content/timeline/Events'
import HorizontalLinkList from '../_common/horizontallinklist/HorizontalLinkList'
import { getPreviewList } from '../preview/utils'
import Resources from '../professional/resources/Resources'
import DistrictFunding from '../professional/funding/DistrictFunding'
import DistrictInvoice from '../professional/funding/DistrictInvoice'
import ProfessionalApp from './ProfessionalApp'
import useUserSettingsQuery from '../../hooks/useUserSettingsQuery'
import useDistrictStatusQuery from '../professional/hooks/useDistrictStatusQuery'
import useDistrictCoursesQuery from '../professional/hooks/useDistrictCoursesQuery'
import useRefreshDistrictDataMutation from '../professional/hooks/useRefreshDistrictDataMutation'
import useTimeline from '../professional/hooks/useTimeline'
import DistrictReadinessReportButton from '../professional/readiness/DistrictReadinessReportButton'
import DistrictReadinessReportPage from '../professional/readiness/DistrictReadinessReportPage'

function DistrictApp({ courses, variables, showTransitionContent, userRole, setLockCurrentOrg }) {
  const { aproUrl } = profile()
  const anchorId = 'professionalCoursesAnchor'

  // Get District status (this should come from cache)
  const {
    data: statusData,
    loading: statusLoading,
    error: statusError,
  } = useDistrictStatusQuery(variables)

  const { events } = useTimeline({ courses: courses?.data })
  const [dashboardUrl, setDashboardUrl] = useState(null)

  return (
    <>
      <TimelineTeaser events={events} />
      <Notifications courses={courses} userRole={CMS_DISTRICT} showAsDropdown={false} />
      <div className="container">
        {!showTransitionContent ? (
          dashboardUrl ? (
            <DistrictReadinessReportPage
              dashboardUrl={dashboardUrl}
              setDashboardUrl={setDashboardUrl}
              setLockCurrentOrg={setLockCurrentOrg}
            />
          ) : (
            <>
              <StatusPanel
                data={statusData}
                hideOrderPanel={true}
                history={false}
                loading={statusLoading}
                AdditionalCostComponent={isDistrictAdmin(userRole) ? DistrictInvoice : null}
              />
              {!statusLoading && !statusError ? (
                <div className="container">
                  <LastUpdated lastUpdated={statusData?.lastUpdated} />
                </div>
              ) : null}
              <div className="row justify-content-center align-items-center">
                <DistrictReadinessReportButton
                  setDashboardUrl={setDashboardUrl}
                  setLockCurrentOrg={setLockCurrentOrg}
                />
              </div>
              {isDistrictSuperAdmin(userRole) ? (
                <div className="row">
                  <div className="col-xs-12">
                    <DistrictFunding />
                  </div>
                </div>
              ) : null}
              <div className="row">
                <div className="col-xs-12">
                  <Courses courses={courses} body={SchoolTable} />
                </div>
              </div>
            </>
          )
        ) : null}
        <Resources
          courses={courses}
          userRole={CMS_DISTRICT}
          showTransitionContent={showTransitionContent}
        />
        <ProfessionalTimeline
          events={EventsByDay(events, true)}
          anchorId={anchorId}
          anchorTitle="AP Registration and Ordering"
        />
      </div>
    </>
  )
}

export default ({ userRole }) => {
  const client = useApolloClient()
  const [districtStatusRefreshed, setDistrictStatusRefreshed] = useState(!isNewLoggedInSession())
  const [lockCurrentOrg, setLockCurrentOrg] = useState(false)
  // Get user details
  const {
    userDetails: { roles, selectedOrgId: orgId },
    educationPeriod: { code: educationPeriod, isTransitionPeriod },
  } = useUserSettingsQuery()
  // Find the role for the selectedOrgId
  const roleForOrg = roles.find(role => role.orgId === orgId)
  // Use the parentOrgId of the selected org to make district data requests
  const variables = { educationPeriod, orgId: roleForOrg.parentOrgId }

  const previewListOptions =
    isDistrictAdmin(userRole) || isDistrictSuperAdmin(userRole)
      ? [USER_PREVIEW_TEACHER, USER_PREVIEW_STUDENT]
      : []

  const previews = getPreviewList({ client, previews: previewListOptions })

  const districtHasAtLeastOneSchoolSetup = roles.find(
    r => r.orgId !== orgId && !r.needToCompletePartForm
  )
  const showTransitionContent =
    !isDistrictFundingAdmin(userRole) && !districtHasAtLeastOneSchoolSetup && isTransitionPeriod

  // Get District status
  const {
    data: statusData,
    loading: statusLoading,
    error: statusError,
  } = useDistrictStatusQuery(variables)

  // Get District courses data
  const {
    data: coursesData,
    loading: coursesLoading,
    error: coursesError,
  } = useDistrictCoursesQuery({
    ...variables,
    otherSkipConditions:
      showTransitionContent || !districtStatusRefreshed || isDistrictFundingAdmin(userRole),
  })

  const { mutate: triggerDistrictRefresh, loading: loadingDistrictRefresh } =
    useRefreshDistrictDataMutation({
      ...variables,
      onCompleted: () => {
        setDistrictStatusRefreshed(true)
        setLoggedInToken()
      },
    })

  useEffect(() => {
    let isCurrent = true
    if (!districtStatusRefreshed && isCurrent) {
      triggerDistrictRefresh()
    }
    return () => {
      isCurrent = false
    }
  }, [districtStatusRefreshed])

  if (loadingDistrictRefresh) {
    return <Spinner spinnerId="districtSpinner" size="64" />
  }

  // District user, but currently selected org is NOT the district og, so display Coordinator view
  if (!roleForOrg.districtOrg) {
    return <ProfessionalApp userRole={roleForOrg.role} />
  }

  // Ensure aggregated district status refresh has been triggered before proceeding
  if (districtStatusRefreshed) {
    // District Funding admin will ONLY see the DistrictFunding component
    if (isDistrictFundingAdmin(userRole)) {
      return (
        <>
          <Banner
            subHeader={<SchoolToggle />}
            navigation={<HorizontalLinkList items={previews} withinBanner={true} />}
          />
          <div className="container">
            <StatusPanel
              data={statusData}
              hideStudentRegistrations={true}
              hideOrderPanel={true}
              history={false}
              loading={statusLoading}
            />
            {!statusLoading && !statusError ? (
              <div className="container">
                <LastUpdated lastUpdated={statusData?.lastUpdated} />
              </div>
            ) : null}
            <DistrictFunding />
          </div>
        </>
      )
    }

    return (
      <>
        <Banner
          subHeader={<SchoolToggle lockCurrentOrg={lockCurrentOrg} />}
          navigation={<HorizontalLinkList items={previews} withinBanner={true} />}
        />
        {!coursesLoading && !coursesError ? (
          <DistrictApp
            userRole={userRole}
            courses={coursesData?.length ? coursesData.map(d => d.data) : []}
            variables={variables}
            showTransitionContent={showTransitionContent}
            setLockCurrentOrg={setLockCurrentOrg}
          />
        ) : null}
      </>
    )
  }
  return null
}
