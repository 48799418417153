import { formatDateTime } from '@myap/ui-library'
import useUserSettingsQuery from '../../../hooks/useUserSettingsQuery'
import ScoreSendModalContent from './ScoreSendModalContent'
import ScoreSendContent from './ScoreSendContent'
import ScoreSendInformationalContent from './ScoreSendInformationalContent'
import ScoreSendReminderContent from './ScoreSendReminderContent'
import { OpenModal } from '../../_common/apricot/Modal'
import * as c from './constants'

function ScoreSend({ position, scoreSendData = {} }) {
  const [usedModal, setUsedModal] = useState(false)
  const { userPromptType } = scoreSendData
  // Get user details
  const { systemDates } = useUserSettingsQuery()
  const { diSelectionEndDate } = systemDates?.deadlines ?? {}

  useEffect(() => {
    // So we don't open modals twice (for top and bottom of the page)
    if (position !== c.ON_PAGE_BOTTOM) {
      if (
        userPromptType === c.USER_PROMPT_MODAL_ACTION ||
        userPromptType === c.USER_PROMPT_MODAL_UPDATE
      ) {
        setUsedModal(true)
        OpenModal(
          ScoreSendModalContent,
          {
            Component: ScoreSendContent,
            isDismissable: userPromptType === c.USER_PROMPT_MODAL_UPDATE,
            isModal: true,
            position,
            userPromptType,
          },
          null
        )
      }
      if (userPromptType === c.USER_PROMPT_MODAL_INFO) {
        setUsedModal(true)
        OpenModal(
          ScoreSendModalContent,
          {
            Component: ScoreSendInformationalContent,
            isDismissable: false,
            title: 'Send Your Official AP Score Report for Free',
            isModal: true,
          },
          null
        )
      }
      if (userPromptType === c.USER_PROMPT_MODAL_REMINDER) {
        setUsedModal(true)
        OpenModal(
          ScoreSendModalContent,
          {
            Component: ScoreSendReminderContent,
            isDismissable: false,
            title: (
              <span style={{ fontSize: '24px' }}>
                Reminder! You can still send a free score report by{' '}
                {formatDateTime(diSelectionEndDate, 'MMMM do')}
              </span>
            ),
            isModal: true,
          },
          null
        )
      }
    }
  }, [position, userPromptType])

  if (
    userPromptType === position ||
    (position === c.ON_PAGE_TOP &&
      (userPromptType === c.USER_PROMPT_MODAL_INFO ||
        userPromptType === c.USER_PROMPT_MODAL_ACTION) &&
      !usedModal) ||
    ((position === c.ON_PAGE_TOP || position === c.ON_PAGE_BOTTOM) &&
      userPromptType === c.USER_PROMPT_MODAL_UPDATE &&
      !usedModal)
  ) {
    return (
      <div className="row">
        <div
          className={`col-xs-12 cb-margin-bottom-24${
            position === c.ON_PAGE_TOP ? ' cb-margin-top-24' : ''
          }`}
        >
          <div className="cb-card">
            <div className="cb-card-header">
              <h2 className="cb-card-title">Send Your Official AP Score Report for Free</h2>
            </div>
            <div className="cb-card-content">
              <ScoreSendContent userPromptType={userPromptType} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return null
}

export default ScoreSend
