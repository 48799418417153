import { NakedButton } from '@cb/apricot-react'
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk'

export default function DistrictReadinessReportPage({
  dashboardUrl,
  setDashboardUrl,
  setLockCurrentOrg,
}) {
  const dashboardRef = useRef([])
  const [embeddingContext, setEmbeddingContext] = useState(null)

  useEffect(() => {
    const createContext = async () => {
      const context = await createEmbeddingContext()
      setEmbeddingContext(context)
    }
    createContext()
    setLockCurrentOrg(true)
  }, [])

  useEffect(() => {
    const embed = async () => {
      const options = {
        url: dashboardUrl,
        container: dashboardRef.current,
        height: '750px',
        width: '100%',
      }
      await embeddingContext.embedDashboard(options)
    }
    if (embeddingContext) {
      embed()
    }
  }, [embeddingContext])

  const handleClick = () => {
    setDashboardUrl(null)
    setLockCurrentOrg(false)
  }

  return (
    <>
      <div className="cb-margin-top-48 cb-margin-bottom-24">
        <NakedButton iconLeft={true} icon="west" onClick={handleClick}>
          Back To MyAP
        </NakedButton>
      </div>
      <div ref={dashboardRef} />
    </>
  )
}
