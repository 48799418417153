import { profile } from '@myap/ui-library'
import axios from 'axios'

const stash = {}
const MAX_SUGGESTIONS = 10
const DEFAULT = { suggestions: [], total: 0 }

export const fetchDiResults = async term => {
  try {
    const { orgcdsConfig } = profile()
    const key = Number.isInteger(parseInt(term, 10)) && term.length === 4 ? 'dicode' : 'name'
    let results = {}

    if (!term) results = DEFAULT
    else if (stash[term]) results = stash[term]
    else {
      const {
        data: {
          hits: { total, hits },
        },
      } = await axios.request({
        method: 'get',
        url: `${orgcdsConfig.url}/disearch`,
        params: { [key]: term, ap_pgm: 'y' },
        withCredentials: false,
        transformRequest: (data, headers) => {
          headers.common = { 'X-Api-Key': orgcdsConfig.apiKey }
          return data
        },
      })
      const suggestions = hits
        .filter(({ _source: h }) => h.di_ap_pgm_active_ind === 'Y' && h.di_code)
        .map(({ _source: h }) => ({
          dicode: h.di_code,
          name: h.org_full_name,
          city: h.org_city,
          state: h.org_state_cd,
          country: h.org_country_iso_cd,
        }))
        .slice(0, MAX_SUGGESTIONS)
      results = { suggestions, total }
      stash[term] = results
    }

    return results
  } catch (err) {
    return DEFAULT
  }
}

export const getSchoolSuggestions = async (suggestion, zip) => {
  const {
    orgcdsConfig: { url, apiKey },
  } = profile()

  try {
    const zipParam = zip ? `&zip=${zip}&distance=100` : ''
    const {
      data: {
        hits: { total, hits = [] },
      },
    } = await axios.request({
      method: 'get',
      url: `${url}/aisearch?name=${suggestion}${zipParam}&size=10&aidomain=ap`,
      withCredentials: false,
      transformRequest: (data, headers) => {
        headers.common = { 'X-Api-Key': apiKey }
        return data
      },
    })
    const rowCount = hits.length > 10 ? 10 : hits.length
    return {
      total,
      suggestions: hits.slice(0, rowCount).map(({ _source: o }) => ({
        orgId: parseInt(o.org_id, 10),
        orgName: o.org_full_name,
        city: o.org_city,
        state: o.org_state_cd,
        country: o.org_country_iso_cd,
        address: `${o.org_street_addr1}, ${o.org_city}, ${o.org_state_cd || o.org_country_iso_cd} ${
          o.org_zip5 || ''
        }`,
      })),
    }
  } catch (err) {
    return { suggestions: [], total: 0 }
  }
}

export const fetchSchoolsByDICode = async (searchFor, maxSuggestions = 10) => {
  const { orgcdsConfig } = profile()
  const searchTerm = Number.isNaN(parseInt(searchFor, 10)) /* || code.length !== 4 */
    ? { name: searchFor }
    : { dicode: searchFor }
  try {
    const {
      data: {
        hits: { total, hits = [] },
      },
    } = await axios.request({
      method: 'get',
      url: `${orgcdsConfig.url}/disearch`,
      params: { ...searchTerm, ap_pgm: 'y' },
      withCredentials: false,
      transformRequest: (data, headers) => {
        headers.common = { 'X-Api-Key': orgcdsConfig.apiKey }
        return data
      },
    })
    const suggestions = hits
      .filter(({ _source: h }) => h.di_code)
      .map(({ _source: h }) => ({
        dicode: h.di_code,
        name: h.org_full_name,
        city: h.org_city,
        state: h.org_state_cd,
        country: h.org_country_iso_cd,
        address: h.org_street_addr1,
        zip: h.org_zip5,
        postal: h.org_intl_postal_code,
        activeInd: h.di_ap_pgm_active_ind === 'Y', // Indicate whether DI is active
      }))
      .slice(0, maxSuggestions)
    const filteredTotal = suggestions.length
    return {
      total: filteredTotal,
      suggestions,
    }
  } catch (err) {
    return { suggestions: [], total: 0 }
  }
}
