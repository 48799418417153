import { useLazyQuery } from '@apollo/client'
import { districtReadinessReportUrlQuery } from '../../../appsync/graphql/professional'
import { formatAppsyncErrors } from '../../../appsync/utils'

const useDistrictReadinessReportUrlQuery = ({ orgId, otherSkipConditions = false }) => {
  const [
    getDistrictReadinessReportUrl,
    { loading, error: rawError = null, data: rawData = {}, refetch, client },
  ] = useLazyQuery(districtReadinessReportUrlQuery, {
    variables: { orgId },
    skip: !orgId || otherSkipConditions,
    fetchPolicy: 'no-cache',
  })
  const data = rawData.getDistrictReadinessReportUrl ?? {}
  const error = rawError ? formatAppsyncErrors(rawError) : null

  return { getDistrictReadinessReportUrl, data, loading, error, refetch }
}

export default useDistrictReadinessReportUrlQuery
