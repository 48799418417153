import { useId } from 'react'
import { BareModal, Icon } from '@cb/apricot-react'
import { adjustModalHeight } from '../../../utilities/dom'

const ID = 'scoreSendModal'

function ScoreSendModalContent({ Component, title, isDismissable, userPromptType }) {
  const modalHeaderId = useId()
  const [shouldCloseModal, setShouldCloseModal] = useState(false)
  const finalTitle = title ?? 'Send Your Official AP Score Report for Free'

  const adjustHeight = () => adjustModalHeight(`#${ID}`)

  return (
    <BareModal
      modalId={ID}
      open={!shouldCloseModal}
      clickOverlayToClose={isDismissable}
      escClose={isDismissable}
      shadowRoot
      modalNode
    >
      <div className={`cb-modal-header${isDismissable ? ' cb-modal-has-close' : ''}`}>
        <h2 className="cb-modal-title" id={`apricot_modal_header_${modalHeaderId}`}>
          {finalTitle}
        </h2>
        {isDismissable ? (
          <button
            type="button"
            className="cb-btn cb-btn-square cb-btn-greyscale cb-btn-close"
            aria-describedby={`apricot_modal_header_${modalHeaderId}`}
            data-cb-modal-close="true"
          >
            <span />
            <Icon name="x-mark" />
            <span className="sr-only">Close Modal</span>
          </button>
        ) : null}
      </div>
      <Component
        isModal={true}
        setShouldCloseModal={setShouldCloseModal}
        adjustHeight={adjustHeight}
        userPromptType={userPromptType}
      />
    </BareModal>
  )
}

ScoreSendModalContent.displayName = 'ScoreSendModalContent'

export default ScoreSendModalContent
